/* eslint-disable @next/next/no-img-element, react/forbid-component-props, react/no-array-index-key, sonarjs/cognitive-complexity */
import classNames from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { LINK_MAIN_HOME } from '../../config/links/links';
import { intervalTime, transitionTime } from '../../config/setup/setup';
import { GO_TO_DEAL, SHOP_ALL_DEALS } from '../../config/text/text';
import { trackEvent } from '../../helpers/analytics';
import { isLightPage } from '../../helpers/browser';
import {
  getCountFromBreakpoint,
  useMerchandisingModuleConfig,
  usePages,
} from '../../helpers/merchandisingModule';
import { useScreenBreakpoint } from '../../helpers/screen';
import { parseWowcherPath } from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';
import PrimaryButton from '../_generic/button/PrimaryButton';
import ArrowButton from '../deal/shared-components/ArrowButton';
import DealPriceSplat from '../deal/shared-components/DealPriceSplat';
import CarouselImage from '../deal/shared-components/carousel/CarouselImage';
/**
 *
 * @param {object} props
 * @param {string} props.path       the page we're on
 * @param {number} props.position   the position on the page **starting from 1**
 * @param {object} props.earlyBirdData
 */
const MerchandisingModule = ({ path, position, earlyBirdData = null }) => {
  const theme = useContext(ThemeContext);
  const {
    details: { isTravel },
  } = parseWowcherPath(path);

  const isEarlyBird = Boolean(earlyBirdData);
  const {
    header,
    subHeader,
    ctaLabel,
    ctaLink,
    backgroundImage,
    deals,
  } = useMerchandisingModuleConfig(path, position, isEarlyBird);
  const breakpoint = useScreenBreakpoint();
  const count = getCountFromBreakpoint(breakpoint, isTravel);
  const pages = usePages(earlyBirdData === null ? deals : earlyBirdData, count);

  const handleButtonClick = () => {
    if (earlyBirdData) {
      trackEvent('viphub: ebviewall click');
    }
  };

  // only show if we have at least 1 full page of deals or if user agent is not a bot
  if (pages.length === 0 || isLightPage()) {
    return null;
  }

  return (
    <>
      <div className="module container__wrapper">
        <div className="module__backdrop" />
        <img
          alt=""
          className="module__background"
          loading="lazy"
          role="presentation"
          src={backgroundImage}
        />
        <div className="container module__content">
          <div className="module__header">
            <div className="module__header-text">
              <h2 className="module__title">{header}</h2>
              <p className="module__subtitle">{subHeader}</p>
            </div>
            <div>
              <a
                href={earlyBirdData !== null ? LINK_MAIN_HOME : ctaLink}
                onClick={handleButtonClick}
              >
                <PrimaryButton aria-label={SHOP_ALL_DEALS(header)}>
                  {ctaLabel}
                </PrimaryButton>
              </a>
            </div>
          </div>
          <Carousel
            centerMode={count === 1}
            className="module__deals"
            emulateTouch={count === 1}
            infiniteLoop
            interval={intervalTime}
            renderArrowNext={(onClick) => {
              if (pages.length === 1) {
                return null;
              } else {
                return (
                  <ArrowButton
                    onClick={() => {
                      trackEvent('horizontal_scroll_arrow_click');
                      onClick();
                    }}
                    orientation="next"
                  />
                );
              }
            }}
            renderArrowPrev={(onClick) => {
              if (pages.length === 1) {
                return null;
              } else {
                return (
                  <ArrowButton
                    onClick={() => {
                      trackEvent('horizontal_scroll_arrow_click');
                      onClick();
                    }}
                    orientation="prev"
                  />
                );
              }
            }}
            renderIndicator={null}
            renderThumbs={() => null}
            showStatus={false}
            swipeable={count === 1}
            transitionTime={transitionTime}
          >
            {pages.map((page, index) => {
              return (
                <div className="module__page row" key={`module-page-${index}`}>
                  {page.map((deal) => {
                    const urlPath = deal.urlPath || deal.shareUrl || '#';

                    return (
                      <div
                        className={classNames(
                          'col module__page--col',
                          { 'col-6': count === 2 },
                          { 'col-4': count === 3 },
                          { 'col-3': count === 4 },
                        )}
                        key={deal.id}
                      >
                        <div className="deal">
                          <div style={{ position: 'relative' }}>
                            <CarouselImage
                              deal={deal}
                              href={urlPath}
                              image={deal.images[0]}
                              lazy
                              load
                              showScrim={false}
                            />
                            <DealPriceSplat deal={deal} discount />
                          </div>
                          <div
                            className="deal-info"
                            style={{
                              padding: 12,
                              textAlign: 'left',
                            }}
                          >
                            <Link href={urlPath ?? ''}>
                              <a
                                aria-label={GO_TO_DEAL.replace(
                                  '##DEAL##',
                                  deal.headline,
                                )}
                                className="title"
                                role="button"
                              >
                                <h3 style={{ margin: 0 }}>{deal.headline}</h3>
                              </a>
                            </Link>
                            {deal.display &&
                              deal.display.bought &&
                              deal.totalBought > 0 && (
                                <p className="bought-count">
                                  {deal.totalBought} {deal.soldText}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      <style jsx>{`
        .module {
          position: relative;
          background-color: rgb(50, 50, 50);
          margin-top: 20px;
          overflow: hidden;
        }
        .module a {
          text-decoration: none;
        }
        .module__backdrop {
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.8),
            transparent
          );
          height: 30%;
          position: absolute;
          top: 0px;
          width: 100%;
          z-index: 1;
        }
        .module__background {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          z-index: 0;
          object-fit: cover;
          transform: translate(-50%, -50%);
        }
        .module__header {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0 0 0;
          width: 100%;
          z-index: 1;
          color: #ffffff;
        }
        .module__title {
          font-size: 25px;
          font-weight: normal;
          margin-bottom: 9px;
          line-height: 1em;
        }
        .module__subtitle {
          font-size: 14px;
          font-weight: normal;
          margin-bottom: 0px;
          line-height: 1em;
        }
        .cta-button {
          height: 40px;
          width: 180px;
          border-radius: 20px;
          background-color: #0000eb;
          color: #ffffff;
          border: none;
          font-weight: bold;
          font-size: 16px;
        }
        .bought-count {
          display: none;
          color: ${theme.colors.primary};
          font-weight: bold;
          margin-bottom: 0;
        }
        :global(.module__deals) {
          overflow: hidden;
          width: 100%;
        }
        :global(.module .slide) {
          padding: 20px 0px 29px 0px;
          background-color: transparent;
        }
        :global(.module .card) {
          width: 100% !important;
        }
        :global(.module__page) {
          height: 100%;
          margin: 0 -10px;
        }
        :global(.module__page--col) {
          padding: 0 10px;
        }
        :global(.module .deal) {
          height: 100%;
          display: flex;
          flex-direction: column;
          background: #ffffff;
          border-radius: 10px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
          overflow: hidden;
        }
        :global(.module .deal-info) {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        :global(.module .title) {
          font-size: 12px;
          color: #000000;
        }
        :global(.module .flickity-button) {
          background-color: white;
          top: 36%; // may not need it anymore
        }
        :global(.module .flickity-button svg) {
          color: ${theme.colors.primary};
        }
        @media only screen and (max-width: ${theme.breakpoints.xlDown}) {
          .module__content {
            width: 666px;
            padding: 0;
          }
        }
        @media only screen and (max-width: ${theme.breakpoints.mdDown}) {
          .module__content {
            width: 100%;
            max-width: 100%;
          }
          .module__header {
            flex-direction: column;
            text-align: center;
          }
          .module__header-text {
            margin-bottom: 20px;
          }
          .bought-count {
            display: block;
          }
          :global(.module .slide) {
            padding: 20px 10px 29px 10px;
            background-color: transparent;
          }
        }
        @media only screen and (max-width: ${theme.breakpoints.smDown}) {
          .module {
            margin-top: 5px;
          }
        }
      `}</style>
    </>
  );
};

export default MerchandisingModule;

MerchandisingModule.propTypes = {
  earlyBirdData: PropTypes.array,
  path: PropTypes.string,
  position: PropTypes.number,
};
